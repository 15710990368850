'use client';
import { observer } from 'mobx-react-lite';
import React, { MouseEvent } from 'react';

import { Product } from '@/shared/types/common';
import { Illustration } from '@/shared/ui';
import { Button } from '@/shared/ui/button';
import { useStore } from '@/store/context';

import styles from './price-card-btn.module.scss';
type AddToCartPriceCardFeatureProps = {
  className?: string;
  product: Product | null;
};

export const AddToCartPriceCardFeature = observer(
  ({ product }: AddToCartPriceCardFeatureProps) => {
    const cart = useStore()?.cart;
    const handleActionButtonClick = (
      e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    ) => {
      if (product) {
        e?.stopPropagation();
        cart?.addToCart({
          id: product.id,
          price: product.price!,
          count: 1,
          status: null,
        });
      }
    };

    return (
      <Button
        className={styles['action-btn']}
        text="Add to cart"
        variant="primary"
        disabled={!product?.price}
        onClick={handleActionButtonClick}
        iconLeft={
          <Illustration
            name={'cart-plus'}
            spriteName={'icons'}
            id={'btn-icon'}
            style={{ color: '#FDFDFD' }}
          />
        }
      />
    );
  },
);
