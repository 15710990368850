'use client';
import {
  PROPERTIES_FILTERS,
  RARITY_FILTERS,
} from '@features/desktop/filter-shop-items/ui/constants';
import { useQueryString } from '@hooks/client';
import cn from 'clsx';
import { AnimatePresence } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/navigation';
import React, { memo } from 'react';

import { SORTING_FILTERS_BY_VARIANT } from '@/features/drop-list-sort/items';
import { ALL_FILTERS, SEARCH_FILTER } from '@/mocks/filters';
import { SEARCH_BAR_FILTERS } from '@/shared/components/drop-list-search/drop-list-search';
import { Illustration } from '@/shared/ui';
import { MotionWrapper } from '@/shared/ui/desktop/motion-wrapper';
import { TagCategory } from '@/shared/ui/tag-category';
import { TagCategoryVariants } from '@/shared/ui/tag-category/tag-category.types';
import { TagRarity } from '@/shared/ui/tag-rarity';
import { TagRarityVariants } from '@/shared/ui/tag-rarity/tag-rarity.types';
import { Typography } from '@/shared/ui/typography';
import { useStore } from '@/store/context';

import styles from './selected-filters.module.scss';

const MemoizedMotionWrapper = memo(MotionWrapper);
const MemoizedTypography = memo(Typography);
const MemoizedIllustration = memo(Illustration);

export const ShopSelectedFilters = observer(() => {
  const shop = useStore().shop;
  const resetChipClasses = cn(styles.chip, styles.reset_chip);
  const router = useRouter();
  const { createQueryString, urlSearchParams, params } = useQueryString();

  const selectedFilters = Array.from(urlSearchParams.values());
  const renderIcon = (name: string) => {
    if (PROPERTIES_FILTERS.find(item => item.filter === name)) {
      return <TagCategory variant={name as TagCategoryVariants} />;
    } else if (RARITY_FILTERS.find(item => item.filter === name)) {
      return <TagRarity variant={name as TagRarityVariants} />;
    } else return null;
  };

  return (
    <div className={styles.selected_filters}>
      <AnimatePresence>
        {selectedFilters?.map(filter => {
          const currentFilter = ALL_FILTERS.filter(
            item => item.group !== 'sort',
          ).find(item => item.filter === filter);

          return currentFilter && currentFilter?.label ? (
            <MemoizedMotionWrapper key={filter} className={styles.chip}>
              {renderIcon(filter)}
              <MemoizedTypography weight="medium" style={{ color: '#606060' }}>
                {currentFilter?.label}
              </MemoizedTypography>
              <MemoizedIllustration
                name={'close-circle-header'}
                spriteName={'icons'}
                size={22}
                style={{ color: '#BFBFBF', cursor: 'pointer' }}
                spanTagClassName={styles.icon}
                onClick={() =>
                  router.push(
                    createQueryString({
                      pathname: '/shop',
                      name: currentFilter?.group,
                      value: currentFilter?.filter,
                      asMultipleParams: false,
                      enableToggle: true,
                    }),
                  )
                }
              />
            </MemoizedMotionWrapper>
          ) : null;
        })}
      </AnimatePresence>
      {selectedFilters
        .filter(item => !SEARCH_FILTER.map(item => item.filter).includes(item))
        .filter(
          item => !SEARCH_BAR_FILTERS.map(item => item.filter).includes(item),
        )
        .filter(
          item =>
            !SORTING_FILTERS_BY_VARIANT['shop']
              .map(item => item.filter)
              .includes(item),
        )?.length > 0 && (
        <div className={resetChipClasses}>
          <MemoizedTypography weight="medium">
            Reset All filters
          </MemoizedTypography>
          <MemoizedIllustration
            name={'close-circle-header'}
            spriteName={'icons'}
            size={22}
            spanTagClassName={styles.icon}
            onClick={() => {
              shop.resetFilters();
              shop.setPrice({ min: 0, max: 1000 });
              router.push(`/shop?sort=${params['sort']}`);
            }}
          />
        </div>
      )}
    </div>
  );
});
