'use client';
import { SidebarFavoritesEntity } from '@entities/sidebar';
import { ContentWidget } from '@widgets/desktop';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/navigation';

import { mockedData } from '@/mocks/items';
import { WithShopItems } from '@/shared/hocs';
import { Illustration, Label } from '@/shared/ui';
import { useStore } from '@/store/context';

import styles from './cards.module.scss';

export const FavoritesCards = observer(() => {
  const favorite = useStore().favorite;
  const router = useRouter();

  return (
    <>
      <ContentWidget
        items={mockedData.filter(item =>
          favorite.favoriteIds.includes(item.realName),
        )}
        emptySlot={
          <SidebarFavoritesEntity
            className={styles.empty}
            topSlot={
              <SidebarFavoritesEntity.Image variant={'favorite-no-items'} />
            }
            middleSlot={
              <SidebarFavoritesEntity.Info
                info={{
                  title: 'Favorites are empty',
                  subtitle:
                    'Add items using the heart️ button on the item card!',
                }}
              />
            }
            bottomSlot={
              <SidebarFavoritesEntity.ActionButton
                text={'Open categories'}
                onClick={() => router.push('/categories')}
                variant={'primary'}
                iconRight={
                  <Illustration
                    id={'sidebar-fav-icon'}
                    spriteName={'icons'}
                    name={'square-arrow-right'}
                  />
                }
                iconLeft={
                  <Illustration
                    id={'sidebar-fav-icon'}
                    spriteName={'icons'}
                    name={'widget'}
                  />
                }
              />
            }
          />
        }
      />
      <WithShopItems>
        {({ items }) => (
          <ContentWidget
            items={items}
            headerSlot={
              <Label
                labelVariant={'yellow'}
                icon={'graph-up'}
                variant={'primary'}
              >
                Popular Items
              </Label>
            }
          />
        )}
      </WithShopItems>
    </>
  );
});
