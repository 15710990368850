import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { Illustration } from '@/shared/ui';
import { Button } from '@/shared/ui/button';
import { useStore } from '@/store/context';

import styles from './styles.module.scss';

export const AddToFavoriteItemCardFeature = observer(
  ({ itemId }: { itemId: number }) => {
    if (!itemId) return;

    const favorite = useStore().favorite;
    const isInFavorite = favorite.favoriteIds.includes(itemId);

    return (
      <Button
        as={motion.button}
        whileTap={{ scale: 0.98 }}
        variant={'secondary'}
        className={styles.container}
        iconLeft={
          <Illustration
            spanTagClassName={styles['icon']}
            spriteName={'icons'}
            name={isInFavorite ? 'heart-broken' : 'heart'}
            style={{ color: '#6941C6' }}
          />
        }
        onClick={() => favorite.toggleFavorite(itemId)}
        text={isInFavorite ? 'Remove from favorite' : 'Add to favorite'}
      />
    );
  },
);
