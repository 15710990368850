'use client';
import { CardItemEntity } from '@entities/desktop/cards';
import { ListCardsWidgetProps } from '@widgets/desktop/list-cards/ui/list-cards.types';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import { useIsClient } from 'usehooks-ts';

import { Label, Skeleton } from '@/shared/ui';
import { useStore } from '@/store/context';

import styles from './list-cards.module.scss';

export const ListCardsWidget = observer(
  ({
    className,
    topSlot = (
      <Label labelVariant={'yellow'} icon={'graph-up'} variant={'primary'}>
        Popular Items
      </Label>
    ),
    cardsVariant,
    items,
    ...props
  }: ListCardsWidgetProps) => {
    const listCardsClasses = cn(styles['list-cards'], className);
    const bottomSlotClasses = cn(
      styles['bottom-slot'],
      styles[`bottom-slot--${cardsVariant}`],
    );
    const isClient = useIsClient();
    const game = useStore()?.app?.getGame;

    return (
      <div className={listCardsClasses} {...props}>
        <div className={styles['top-slot']}>{topSlot}</div>

        <div className={bottomSlotClasses}>
          {isClient ? (
            Array.isArray(items) &&
            items.length > 0 &&
            items.map((item, idx) => {
              return (
                <CardItemEntity
                  applyHandleClickOnFullCard
                  isMM2Variant={game === 'mm2'}
                  variant={cardsVariant}
                  key={`card-${idx}-${item.realName}-${item?.name}`}
                  item={item}
                />
              );
            })
          ) : (
            <>
              {Array.isArray(items) &&
                items.length > 0 &&
                items.map((_, idx) => (
                  <Skeleton
                    key={`skeleton-list-cards-${idx}`}
                    className={cn(
                      styles['list-card-skeleton'],
                      styles[`variant--${cardsVariant}`],
                    )}
                  />
                ))}
            </>
          )}
        </div>
      </div>
    );
  },
);
