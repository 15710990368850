'use client';
import { CardItemBlockEntity } from '@entities/desktop/cards';
import { AddToFavoriteItemCardFeature } from '@features/desktop/pet/item-card/add-to-favorite/ui/add-to-favorite';
import {
  ItemCardWidgetBreadcrumbsProps,
  ItemCardWidgetDescriptionProps,
  ItemCardWidgetItemAgeProps,
  ItemCardWidgetItemPropertiesProps,
  ItemCardWidgetItemProps,
  ItemCardWidgetItemTitleProps,
  ItemCardWidgetProps,
} from '@widgets/desktop/item-card/ui/item-card.types';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import Link from 'next/link';
import { notFound, usePathname, useRouter } from 'next/navigation';
import React from 'react';

import { ALL_FILTERS } from '@/mocks/filters';
import { mockedItemsGroups } from '@/mocks/items';
import { mockedMM2ItemsGroups } from '@/mocks/mm2Items';
import { commonAges, neonAges } from '@/mocks/petsAges';
import { GlobalItemGroups, ItemAge, Product } from '@/shared/types/common';
import { Illustration, TagInfo } from '@/shared/ui';
import { Breadcrumbs } from '@/shared/ui/desktop/breadcrumbs';
import {
  BreadcrumbsItem,
  BreadcrumbsItemSeparator,
} from '@/shared/ui/desktop/breadcrumbs/breadcrumbs';
import { Tabs, TabsItem } from '@/shared/ui/tabs';
import { TagInfoVariant } from '@/shared/ui/tag-info/tag-info.types';
import { Typography } from '@/shared/ui/typography';
import { useStore } from '@/store/context';

import styles from './item-card.module.scss';

export const ItemCardWidget = ({
  className,
  category,
  productId,
  pet,
  ...props
}: ItemCardWidgetProps) => {
  const itemCardClasses = cn(styles['item-card'], className);
  const router = useRouter();
  const appStore = useStore().app;

  const item = (
    appStore.selectedGame === 'mm2' ? mockedMM2ItemsGroups : mockedItemsGroups
  ).find(item => item.realName === pet);
  const product = item?.products.find(product => product.id === productId);

  if (!item || !product) notFound();

  const handlePropertieChange = (
    item: GlobalItemGroups,
    query: Partial<Product>,
  ) => {
    const matchingProduct = item.products.find(product =>
      Object.keys(query).every(
        key =>
          query[key as keyof Partial<Product>] === undefined ||
          product[key as keyof Product] ===
            query[key as keyof Partial<Product>],
      ),
    );

    if (matchingProduct) {
      router.replace(
        `/shop/${item?.type}/${item?.realName}/${matchingProduct.id}`,
      );
    }
  };

  return (
    <div className={itemCardClasses} {...props}>
      <ItemCardWidget.Breadcrumbs item={item} />

      <div className={styles['content']}>
        <div className={styles['left']}>
          <ItemCardWidget.Item item={item} />
          <AddToFavoriteItemCardFeature itemId={product!.id} />
        </div>
        <div className={styles['right']}>
          <div className={styles['title-info']}>
            <ItemCardWidget.ItemTitle name={item?.name} />
            <ItemCardWidget.ItemTags rare={item?.rare ?? 'common'} />
          </div>
          <ItemCardWidget.ItemType
            item={item}
            product={product}
            handlePropertiesChange={handlePropertieChange}
          />
          <ItemCardWidget.ItemProperties
            item={item}
            product={product}
            handlePropertiesChange={handlePropertieChange}
          />
          <ItemCardWidget.ItemAge
            item={item}
            product={product}
            handlePropertiesChange={handlePropertieChange}
          />
          {/*<ItemCardWidget.Bundles product={product} />*/}
          <div className={styles['descr']}>
            <ItemCardWidget.Description text={item?.description} />
          </div>
        </div>
      </div>

      <div className={styles['descr-content']}>
        <ItemCardWidget.Description text={item?.realName} />
      </div>
    </div>
  );
};

ItemCardWidget.ItemTitle = observer(
  ({ name }: ItemCardWidgetItemTitleProps) => {
    return (
      <Typography className={styles['title']} weight={'semi-bold'}>
        {name}
      </Typography>
    );
  },
);

ItemCardWidget.ItemTags = observer(({ rare }: { rare: TagInfoVariant }) => {
  const tags: { variant: TagInfoVariant; text: string }[] = [
    { variant: 'discount', text: '-30%' },
    { variant: 'hot-price', text: 'Hot Price' },
  ];

  return (
    <div className={styles['tags']}>
      <TagInfo
        key={`card-item-${tags[1]?.variant}-${1}`}
        className={styles['tag-info']}
        variant={tags[1]?.variant}
      >
        {tags[1]?.text}
      </TagInfo>
      <TagInfo className={styles['tag-info']} variant={rare}>
        {rare}
      </TagInfo>
      <TagInfo
        key={`card-item-${tags[0]?.variant}-${0}`}
        className={styles['tag-info']}
        variant={tags[0]?.variant}
      >
        {tags[0]?.text}
      </TagInfo>
    </div>
  );
});

ItemCardWidget.ItemType = observer(
  ({
    item,
    product,
    handlePropertiesChange,
  }: {
    item?: GlobalItemGroups;
    product?: Product;
    handlePropertiesChange: (
      item: GlobalItemGroups,
      query: Partial<Product>,
    ) => void;
  }) => {
    if (!item || !product || item.game === 'mm2' || item.type !== 'pet')
      return null;

    const defaultActiveTab = product.pumping;

    return (
      <div className={styles['properties']}>
        <Typography className={styles['title']}>Item type</Typography>
        <Tabs<'neon' | 'mega_neon' | 'default'>
          className={styles['tabs-container']}
          defaultActiveTab={defaultActiveTab!}
          isSelectable
          onTabsChange={tab =>
            handlePropertiesChange(item, {
              pumping: tab,
              flyable: product.flyable,
              rideable: product.rideable,
            })
          }
        >
          <TabsItem
            className={styles['tab-item']}
            tagCategory={'menu'}
            variant={'primary'}
            value={'default'}
          >
            Common
          </TabsItem>
          <TabsItem
            className={styles['tab-item']}
            tagCategory={'neon'}
            variant={'primary'}
            value={'neon'}
          >
            Neon
          </TabsItem>
          <TabsItem
            className={styles['tab-item']}
            tagCategory={'mega_neon'}
            variant={'primary'}
            value={'mega_neon'}
          >
            Mega-Neon
          </TabsItem>
        </Tabs>
      </div>
    );
  },
);

ItemCardWidget.ItemProperties = observer(
  ({
    item,
    product,
    handlePropertiesChange,
  }: ItemCardWidgetItemPropertiesProps) => {
    const router = useRouter();

    if (!item || !product || (item.game === 'adopt' && item.type !== 'pet'))
      return null;

    const hasChromaSet = 'chromaSetId' in item && item.chromaSetId;

    return (
      <div className={styles['properties']}>
        <Typography className={styles['title']}>
          {item.game === 'mm2' ? 'Type' : 'Properties'}
        </Typography>
        {item.game === 'adopt' && item.type === 'pet' && (
          <div className={styles['tabs-container']}>
            <TabsItem
              className={styles['tab-item']}
              tagCategory={'fly'}
              variant={'primary'}
              onClick={() =>
                handlePropertiesChange(item, {
                  pumping: product?.pumping,
                  age: product?.age,
                  rideable: product?.rideable,
                  flyable: !product?.flyable,
                })
              }
              isSelected={Boolean(product.flyable)}
              value={'ride'}
            >
              Flyable
            </TabsItem>
            <TabsItem
              className={styles['tab-item']}
              tagCategory={'ride'}
              variant={'primary'}
              onClick={() =>
                handlePropertiesChange(item, {
                  pumping: product?.pumping,
                  age: product?.age,
                  flyable: product?.flyable,
                  rideable: !product?.rideable,
                })
              }
              isSelected={Boolean(product.rideable)}
              value={'ride'}
            >
              Rideable
            </TabsItem>
          </div>
        )}
        {item.game === 'mm2' && (
          <Tabs
            className={styles['tabs-container']}
            defaultActiveTab={item.type}
            isSelectable
            onTabsChange={() =>
              item.game === 'mm2' &&
              hasChromaSet &&
              router.push(`/shop/${item.type}/${item?.chromaSetId}`)
            }
          >
            <TabsItem
              className={styles['tab-item']}
              variant={'quaternary'}
              disabled={!hasChromaSet && !item.type}
              value={'chroma'}
            >
              Chroma
            </TabsItem>
          </Tabs>
        )}
      </div>
    );
  },
);

ItemCardWidget.ItemAge = observer(
  ({ item, product, handlePropertiesChange }: ItemCardWidgetItemAgeProps) => {
    if (
      !item ||
      !product ||
      item.type !== 'pet' ||
      product.pumping === 'mega_neon'
    )
      return;

    return (
      <div className={styles['age']}>
        <Typography className={styles['title']}>Age</Typography>
        <Tabs<ItemAge>
          className={styles['tabs-container']}
          defaultActiveTab={product.age!}
          isSelectable
          onTabsChange={value => {
            handlePropertiesChange(item, {
              age: value,
              flyable: product?.flyable,
              rideable: product?.rideable,
            });
          }}
        >
          {(product.pumping === 'default' ? commonAges : neonAges).map(age => (
            <TabsItem
              key={age.value}
              className={styles['tab-item']}
              variant={'quaternary'}
              value={age.value}
            >
              {age.label}
            </TabsItem>
          ))}
        </Tabs>
      </div>
    );
  },
);

// ItemCardWidget.Bundles = observer(({ itemId }: ItemCardWidgetItemAgeProps) => {
//   const shop = useStore()?.shop;
//   const game = useStore().app.getGame;
//   const item = itemId && shop?.getMappedCards.get(itemId);
//   const hasBundles = item && 'bundles' in item;
//
//   const dragScrollRef = useDragToScroll<HTMLDivElement>();
//
//   //@TODO добавить обработку возвраста пета
//   if (game === 'adopt' || !hasBundles) return;
//
//   const router = useRouter();
//
//   return (
//     <div className={styles['bundles']}>
//       <Typography className={styles['title']}>Bundles</Typography>
//       <div ref={dragScrollRef} className={styles['bundles-list-wrapper']}>
//         <div className={styles['bundles-list']}>
//           {hasBundles &&
//             Array.isArray(item?.bundles) &&
//             item?.bundles.map(bundle => {
//               return (
//                 <CardOfferEntity
//                   key={`${bundle.id}-bundle`}
//                   onClick={() =>
//                     router.push(`/shop/${bundle.category}/${bundle?.id}`)
//                   }
//                   topSlot={
//                     <CardOfferEntityItem
//                       isMM2Variant={game === 'mm2'}
//                       tagsInfo={bundle.tagsInfo}
//                       item={bundle.item}
//                       rarity={bundle.rarity}
//                     />
//                   }
//                   bottomSlot={
//                     <CardOfferEntityItemInfo
//                       price={{
//                         old: bundle.info.price.old,
//                         current: bundle.info.price.current,
//                       }}
//                       title={bundle.info.title}
//                     />
//                   }
//                 />
//               );
//             })}
//         </div>
//       </div>
//     </div>
//   );
// });

ItemCardWidget.Breadcrumbs = observer(({ item }: ItemCardWidgetItemProps) => {
  const pathname = usePathname();

  if (!item) return null;

  const categoryTitle = item.type
    ? ALL_FILTERS.find(filter => filter.filter === item.type)?.label
    : 'All';

  return (
    <div className={styles['breadcrumbs']}>
      <Breadcrumbs>
        <BreadcrumbsItem
          isActive={RegExp('^\\/[a-zA-Z]{2}$').test(pathname)}
          as={Link}
          href={'/'}
        >
          Market
        </BreadcrumbsItem>
        <BreadcrumbsItemSeparator />
        <BreadcrumbsItem
          isActive={RegExp('^\\/[a-zA-Z]{2}/categories$').test(pathname)}
          as={Link}
          href={'/categories'}
        >
          Catalog
        </BreadcrumbsItem>
        <BreadcrumbsItemSeparator />
        <BreadcrumbsItem
          isActive={RegExp('^\\/[a-zA-Z]{2}/shop$').test(pathname)}
          as={Link}
          href={`/shop?category=${item.type}`}
        >
          {categoryTitle ?? 'All'}
        </BreadcrumbsItem>
        <BreadcrumbsItemSeparator />
        <BreadcrumbsItem
          as={Link}
          isDisabled
          isActive={RegExp('^\\/([a-zA-Z]{2})\\/shop\\/.*$').test(pathname)}
          href={`/shop/${item.realName}`}
        >
          {item.name}
        </BreadcrumbsItem>
      </Breadcrumbs>
    </div>
  );
});

ItemCardWidget.Item = observer(({ item }: ItemCardWidgetBreadcrumbsProps) => {
  if (!item) return null;

  return (
    <div className={styles['item']}>
      <CardItemBlockEntity
        isMM2Variant={item.game === 'mm2'}
        src={item.imageUri}
        rare={item.rare}
        size={'l'}
      />
    </div>
  );
});

// eslint-disable-next-line react/display-name
ItemCardWidget.Description = observer(
  ({ text }: ItemCardWidgetDescriptionProps) => {
    return text ? (
      <div className={styles['description']}>
        <div className={styles['description-container']}>
          <Illustration
            style={{ color: '#6941C6' }}
            name={'document-text'}
            size={22}
            spriteName={'icons'}
          />
          <Typography className={styles['title']}>Description</Typography>
        </div>

        <Typography className={styles['text']}>{text}</Typography>
      </div>
    ) : null;
  },
);
