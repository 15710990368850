'use client';
import { CardItemEntity } from '@entities/desktop/cards';
import { AddToCartFeature } from '@features/desktop/add-to-cart';
import { AddToFavoriteFeature } from '@features/desktop/add-to-favorite/ui';
import { SelectPetPropertiesFeature } from '@features/select-pet-properties';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/navigation';
import React, { ReactNode } from 'react';

import {
  DivElementType,
  GlobalItem,
  GlobalMM2Item,
} from '@/shared/types/common';
import { useStore } from '@/store/context';

import styles from './content.module.scss';

export type Items = {
  items: (GlobalItem | GlobalMM2Item)[];
};
export type ContentWidgetItemsBlockProps = DivElementType & {
  headerSlot?: ReactNode;
  emptySlot?: ReactNode;
} & Items;

export const ContentWidget = observer(
  ({ headerSlot, emptySlot, items }: ContentWidgetItemsBlockProps) => {
    if (!items.length && emptySlot) {
      return emptySlot;
    }

    return (
      <div className={styles['items-block']}>
        {headerSlot && <div className={styles['header']}>{headerSlot}</div>}
        <div className={styles['items']}>
          {items?.map((item, idx) => {
            return (
              <CardItemEntity
                key={`${item?.realName}${idx}`}
                variant={'tertiary'}
                item={item}
                // tagsInfo={item?.tagsInfo}
              />
            );
          })}
        </div>
      </div>
    );
  },
);
